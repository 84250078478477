import { Box, CircularProgress, Grid } from '@mui/material'
import Header from 'components/molecules/Header'
import { useUserRunQuery } from 'domains/userRuns/queries'
import { HISTORIC_PATH } from 'enums/paths'
import { isEmpty } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import FeelingsDashboard from './components/FeelingsDashboard'
import RunInformations from './components/RunInformations'
import SatisfactionDashboard from './components/SatisfactionDashboard'
import StepsDashboard from './components/StepsDashboard'
import UserInformationCard from './components/UserInformationCard'
import PathDashboard from './components/PathDashboard'
import UseRunInformations from './components/UseRunInformations'

function UserRunDashboard() {
  const { t } = useTranslation()
  const { id } = useParams()
  const { data: userRun } = useUserRunQuery({ id }, { enabled: !!id })

  return (
    <>
      <Header
        title={t('run-dashboard')}
        backButton
        backButtonUrl={HISTORIC_PATH}
      />
      <Box
        sx={{
          mt: 1,
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {!isEmpty(userRun) ? (
          <Box>
            <RunInformations userRun={userRun} />
            <UseRunInformations userRun={userRun} />
            <UserInformationCard key={0} userRun={userRun} />
            <Grid sx={{ mt: 1 }} container spacing={2}>
              <Grid item md={12} lg={6}>
                <FeelingsDashboard userRun={userRun} />
              </Grid>
              <Grid item md={12} lg={6}>
                <SatisfactionDashboard userRun={userRun} />
              </Grid>
              <Grid item md={12} lg={6}>
                <StepsDashboard userRun={userRun} />
              </Grid>
            </Grid>
            <Box
              sx={{
                mt: 2,
                height: (theme) => theme.spacing(50),
                width: { lg: 0.5, md: 1 },
              }}
            >
              <PathDashboard userRun={userRun} />
            </Box>
          </Box>
        ) : (
          <CircularProgress />
        )}
      </Box>
    </>
  )
}

export default UserRunDashboard
