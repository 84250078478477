import { Box, Tooltip, Typography } from '@mui/material'
import { GridSortModel } from '@mui/x-data-grid'
import { useAuth } from 'contexts/AuthContext'
import { useLevelDefaultssQuery } from 'domains/level-defaults/queries'
import { useRunsQuery } from 'domains/runs/queries'
import { useThematicsQuery } from 'domains/thematics/queries'
import { useTownsQuery } from 'domains/towns/queries'
import { useAgeRangesQuery } from 'domains/users/queries'
import { SortDirection } from 'enums/filters'
import { formatDistance, formatDuration } from 'helpers/formatter'
import { isAdmin } from 'helpers/role'
import { isNil } from 'lodash'
import { DateTime } from 'luxon'
import React from 'react'
import { useTranslation } from 'react-i18next'

function TooltipComponent({ value }) {
  return (
    <Tooltip title={value || ''}>
      <Typography variant="body2" noWrap>
        {value || ''}
      </Typography>
    </Tooltip>
  )
}

const useUserRunsGrid = () => {
  const { t } = useTranslation()
  const { user } = useAuth()
  const { data: towns } = useTownsQuery()
  const { data: runs } = useRunsQuery()
  const { data: thematics } = useThematicsQuery()
  const { data: levels } = useLevelDefaultssQuery()
  const { data: ageRanges } = useAgeRangesQuery()
  const supervisorColumns = [
    {
      field: 'osName',
      headerName: t('os-name'),
      editable: false,
      width: 90,
      renderCell: (params) => <TooltipComponent value={params.value} />,
    },
    {
      field: 'osVersion',
      headerName: t('os-version'),
      editable: false,
      width: 90,
      renderCell: (params) => <TooltipComponent value={params.value} />,
    },
    {
      field: 'deviceBrand',
      headerName: t('device-brand'),
      editable: false,
      width: 90,
      renderCell: (params) =>
        !isNil(params.value) && <TooltipComponent value={params.value} />,
    },
    {
      field: 'deviceModel',
      headerName: t('device-model'),
      editable: false,
      width: 90,
      renderCell: (params) =>
        !isNil(params.value) && <TooltipComponent value={params.value} />,
    },
  ]

  const columns = [
    {
      field: 'startDate',
      headerName: t('start-date'),
      editable: false,
      filterable: false,
      type: 'date',
      width: 160,
      valueGetter: (params) => {
        const date = DateTime.fromISO(params.value).toLocaleString(
          DateTime.DATE_SHORT,
        )
        const hour = DateTime.fromISO(params.value).toLocaleString(
          DateTime.TIME_24_SIMPLE,
        )
        return `${date} - ${hour}`
      },
    },
    {
      field: 'user.firstName',
      headerName: t('user'),
      editable: false,
      width: 150,
      renderCell: (params) => (
        <TooltipComponent value={params.row?.user?.firstName} />
      ),
    },
    {
      field: 'user.email',
      headerName: t('email'),
      editable: false,
      width: 150,
      renderCell: (params) => (
        <TooltipComponent value={params.row?.user?.email} />
      ),
    },
    {
      field: 'user.ageRange.name',
      filterKey: 'user.ageRange.id',
      headerName: t('age'),
      editable: false,
      type: 'singleSelect',
      valueOptions:
        ageRanges &&
        ageRanges.map(({ id, name }) => ({
          value: id,
          label: name,
        })),
      width: 90,
      renderCell: (params) => (
        // eslint-disable-next-line no-underscore-dangle
        <TooltipComponent value={params.row?.user?._AgeRange} />
      ),
    },
    {
      field: 'run.town.name',
      filterKey: 'run.town.id',
      valueOptions:
        towns &&
        towns.data.map(({ id, name }) => ({
          value: id,
          label: name,
        })),
      headerName: t('ville'),
      editable: false,
      filterable: false,
      type: 'singleSelect',
      width: 200,
      renderCell: (params) => (
        // eslint-disable-next-line no-underscore-dangle
        <TooltipComponent value={params.row?.run?._Town} />
      ),
    },
    {
      field: 'run.name',
      filterKey: 'run.id',
      valueOptions:
        runs &&
        runs.data.map(({ id, name, _Town }) => ({
          value: id,
          label: `${name} - ${_Town}`,
        })),
      headerName: t('run'),
      editable: false,
      type: 'singleSelect',
      width: 200,
      renderCell: (params) => (
        <TooltipComponent value={params.row?.run?.name} />
      ),
    },
    {
      field: 'thematic.name',
      filterKey: 'thematic.id',
      valueOptions:
        thematics &&
        thematics.data.map(({ id, name }) => ({
          value: id,
          label: name,
        })),
      headerName: t('thematic'),
      editable: false,
      type: 'singleSelect',
      width: 150,
      renderCell: (params) => (
        <Tooltip title={params.row?.thematic?.name || ''}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                width: (theme) => theme.spacing(3),
                height: (theme) => theme.spacing(3),
                mr: 1,
                '& img': {
                  width: '100%',
                },
              }}
            >
              <img
                src={params.row?.thematic?.picture?.url}
                alt={params.row?.thematic?.name}
              />
            </Box>
            {params.row?.thematic?.name}
          </Box>
        </Tooltip>
      ),
    },
    {
      field: 'level.name',
      filterKey: 'level.name',
      headerName: t('level'),
      editable: false,
      type: 'singleSelect',
      valueOptions:
        levels &&
        levels.data.map(({ id, name }) => ({
          value: name,
          label: name,
        })),
      width: 150,
      renderCell: (params) => (
        <TooltipComponent value={params.row?.level?.name} />
      ),
    },
    {
      field: 'completed',
      headerName: t('completed'),
      editable: false,
      type: 'boolean',
      width: 90,
      renderCell: (params) => {
        const isCompleted = params.row.completed
        const cellText = isCompleted ? '✔' : '✘'

        return (
          <Typography
            sx={{
              color: (theme) =>
                isCompleted
                  ? theme.palette.success.main
                  : theme.palette.warning.main,
            }}
          >
            {cellText}
          </Typography>
        )
      },
    },
    {
      field: 'DurationInMinutes',
      headerName: t('duration-no-unit'),
      editable: false,
      type: 'singleSelect',
      width: 90,
      renderCell: (params) => (
        <TooltipComponent
          value={
            params.row?.durationInMinutes &&
            `${formatDuration(params.row?.durationInMinutes)}`
          }
        />
      ),
    },
    {
      field: 'DistanceInMeters',
      headerName: t('distance-no-unit'),
      editable: false,
      type: 'singleSelect',
      width: 90,
      renderCell: (params) => (
        <TooltipComponent
          value={
            params.row?.distanceInMeters &&
            `${formatDistance(params.row?.distanceInMeters)}`
          }
        />
      ),
    },
    {
      field: 'appVersion',
      headerName: t('app-version'),
      editable: false,
      width: 90,
      renderCell: (params) => <TooltipComponent value={params.value} />,
    },
    ...(isAdmin(user?.roles[0].key) ? supervisorColumns : []),
  ]

  const defaultSort: GridSortModel = [
    {
      field: 'startDate',
      sort: SortDirection.DESC,
    },
  ]

  return { columns, defaultSort }
}

export default useUserRunsGrid
