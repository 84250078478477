import {
  DataSaverOn,
  Download,
  FormatListBulleted,
  Person,
  Settings,
  Watch,
} from '@mui/icons-material'
import AlignHorizontalCenterIcon from '@mui/icons-material/AlignHorizontalCenter'
import DashboardIcon from '@mui/icons-material/Dashboard'
import GroupIcon from '@mui/icons-material/Group'
import HistoryIcon from '@mui/icons-material/History'
import HandshakeIcon from '@mui/icons-material/Handshake'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import { ReactComponent as Logo } from 'assets/svg/logo.svg'
import {
  APP_PARAMS_PATH,
  AVAILABILITIES_PATH,
  CUSTOMERS_PATH,
  HISTORIC_PATH,
  HOME_PATH,
  LEVEL_DEFAULTS_PATH,
  PARTNERS_PATH,
  QUESTIONS_ANSWERS_PATH,
  RUNS_PATH,
  THEMATICS_PATH,
  USERS_PATH,
  USER_FIELDS_PARAMETERS_PATH,
} from 'enums/paths'
import { isAdmin } from 'helpers/role'

export const LINK = 'link'
export const DIVIDER = 'divider'
export const EXPORT = 'export'

export const adminMenuItems = (t) => [
  {
    key: 'questionsAnswers',
    type: LINK,
    title: t('questions-answers'),
    icon: QuestionMarkIcon,
    to: QUESTIONS_ANSWERS_PATH,
  },
  {
    key: 'runs',
    type: LINK,
    title: t('runs'),
    icon: Logo,
    to: RUNS_PATH,
  },
  {
    key: 'thematics',
    type: LINK,
    title: t('thematics'),
    icon: FormatListBulleted,
    to: THEMATICS_PATH,
  },
  {
    key: 'levelDefaults',
    type: LINK,
    title: t('levels'),
    icon: AlignHorizontalCenterIcon,
    to: LEVEL_DEFAULTS_PATH,
  },
  {
    key: 'availabilities',
    type: LINK,
    title: t('availabilities'),
    icon: Watch,
    to: AVAILABILITIES_PATH,
  },
  {
    key: 'customers',
    type: LINK,
    title: t('customers'),
    icon: Person,
    to: CUSTOMERS_PATH,
  },
  {
    key: 'partners',
    type: LINK,
    title: t('partners'),
    icon: HandshakeIcon,
    to: PARTNERS_PATH,
  },
  {
    key: 'user-fields-parameter',
    type: LINK,
    title: t('user-fields-parameters'),
    icon: DataSaverOn,
    to: USER_FIELDS_PARAMETERS_PATH,
  },
  {
    key: 'users',
    type: LINK,
    title: t('users-management'),
    icon: GroupIcon,
    to: USERS_PATH,
  },
  {
    key: 'export',
    type: EXPORT,
    title: t('export-data'),
    icon: Download,
  },
  {
    key: 'app-params',
    type: LINK,
    title: t('app-params'),
    icon: Settings,
    to: APP_PARAMS_PATH,
  },
]

export const getMenuItems = (t, user) => {
  const menuItems = []
  menuItems.push(
    {
      key: 'dashboard',
      type: LINK,
      title: t('dashboard'),
      icon: DashboardIcon,
      to: HOME_PATH,
    },
    {
      key: 'history',
      type: LINK,
      title: t('history'),
      icon: HistoryIcon,
      to: HISTORIC_PATH,
    },
  )
  if (isAdmin(user.roles[0].key)) {
    adminMenuItems(t).forEach((item) => menuItems.push(item))
  }
  return menuItems
}
