export function sortArrayOfObjectByDate<Type>(
  array: Type[],
  datePropertyName: string,
): Type[] {
  array.sort(
    (a, b) =>
      new Date(a[datePropertyName]).getTime() -
      new Date(b[datePropertyName]).getTime(),
  )
  return array
}
