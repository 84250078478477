import { Box } from '@mui/material'
import { ReactComponent as Logo } from 'assets/svg/logo-complete.svg'
import React from 'react'
import { Link } from 'react-router-dom'
import { HOME_PATH } from 'enums/paths'

function Title() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        mt: -2,
        height: (theme) => theme.spacing(18),
      }}
    >
      <Link style={{ height: '100%', width: '100%' }} to={HOME_PATH}>
        <Logo />
      </Link>
    </Box>
  )
}

export default Title
