import { Availability } from 'domains/availabilities/types'
import { isEmpty } from 'lodash'

export function formatAvailabilities(collection: Availability[]) {
  const availabilities = []
  if (!isEmpty(collection)) {
    collection.map((element) =>
      availabilities.push({
        label: element.name,
        id: element.id,
      }),
    )
  }
  return availabilities
}
