import { Box, IconButton, Typography } from '@mui/material'
import ScrollShadowBox from 'components/templates/ScrollShadowBox'
import { orderBy } from 'lodash'
import React from 'react'
import { MAX_SIZE } from '../../enums'
import { getIncreasingSizeFromIndex } from '../../helpers'
import AddLevelButton from '../AddLevelButton'
import { Props } from './types'

function VerticalSelector({ thematic, selected, setSelected }: Props) {
  const orderedLevels = orderBy(thematic?.levels, ['index'])

  const handleSelect = (id) => {
    setSelected(id)
  }

  return (
    <ScrollShadowBox
      sx={{
        overflowY: 'auto',
        width: (theme) => theme.spacing(18.75),
        maxHeight: 'calc(100vh - 200px)',
      }}
    >
      <Box sx={{ display: 'flex' }}>
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            pb: 0,
          }}
        >
          <Box
            sx={{
              mt: 3,
              zIndex: 500,
              position: 'absolute',
              width: 0,
              height: 'calc(100% - 40px)',
              borderLeft: (theme) => `3px solid ${theme.palette.primary.main}`,
            }}
          />
          {orderedLevels.map((level, i) => {
            const isSelected = level.id === selected
            const size = getIncreasingSizeFromIndex(i, orderedLevels.length)

            return (
              <Box
                sx={{
                  zIndex: 600,
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mr: '-40px',
                }}
                key={level.id}
              >
                <Box
                  sx={{
                    height: 70,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <IconButton
                    onClick={() => handleSelect(level.id)}
                    sx={{
                      borderRadius: '50%',
                      bgcolor: isSelected
                        ? 'common.black'
                        : 'background.default',
                      border: (theme) =>
                        `3px solid ${
                          isSelected
                            ? theme.palette.common.black
                            : theme.palette.primary.main
                        }`,
                      height: isSelected ? MAX_SIZE : size,
                      width: isSelected ? MAX_SIZE : size,
                      '&:hover': {
                        bgcolor: isSelected
                          ? 'common.black'
                          : 'background.default',
                      },
                      transition:
                        'border-color 0.2s, background-color 0.2s, height 0.2s ease-out, width 0.2s ease-out',
                    }}
                  />
                </Box>
                <Box sx={{ height: 70, width: 40 }}>
                  <Typography
                    textAlign="center"
                    sx={{
                      color: isSelected ? 'text.primary' : 'primary.main',
                      transition: 'color 0.2s',
                      width: '100%',
                      position: 'absolute',
                      left: 30,
                      fontSize: 48,
                      fontWeight: 700,
                    }}
                  >
                    {i + 1}
                  </Typography>
                </Box>
              </Box>
            )
          })}
          <Box
            sx={{
              zIndex: 600,
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            key="add-btn"
          >
            <AddLevelButton thematic={thematic} setSelected={setSelected} />
          </Box>
        </Box>
      </Box>
    </ScrollShadowBox>
  )
}

export default VerticalSelector
