import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import ControlledTextField from 'components/molecules/ControlledTextField'
import ControlledUploadFile from 'components/molecules/ControlledUploadFile'
import { isEmpty } from 'lodash'
import React from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useAvailabilityForm from './form'
import { Props } from './types'

function AvailabilityForm({ open, selectedId, handleDialog }: Props) {
  const { t } = useTranslation()
  const isCreation = isEmpty(selectedId)

  const { form, onSubmit } = useAvailabilityForm({
    id: selectedId,
    handleDialog,
  })

  const handleClose = () => handleDialog()

  return (
    <Dialog open={open} maxWidth="xs" fullWidth onClose={() => handleDialog()}>
      <FormProvider {...form}>
        <Box component="form" onSubmit={form.handleSubmit(onSubmit)}>
          <DialogTitle>
            {isCreation ? t('availability-create') : t('availability-edit')}
          </DialogTitle>
          <DialogContent
            sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <ControlledTextField fullWidth name="name" label={t('name')} />

              <ControlledTextField
                fullWidth
                name="description"
                label={t('description')}
              />

              <ControlledTextField
                fullWidth
                type="number"
                name="thinkingTime"
                label={t('thinking-time')}
              />
              <Box
                sx={{
                  '& img': {
                    width: (theme) => `${theme.spacing(25)} !important`,
                  },
                  '& p': {
                    marginBottom: (theme) => theme.spacing(2),
                  },
                }}
              >
                <ControlledUploadFile name="picture" label={t('picture')} />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{t('cancel')}</Button>
            <Button variant="contained" type="submit">
              {isCreation ? t('common-save') : t('common-update')}
            </Button>
          </DialogActions>
        </Box>
      </FormProvider>
    </Dialog>
  )
}

export default AvailabilityForm
