/* eslint-disable react/jsx-props-no-spreading */
import { Box, Button } from '@mui/material'
import ControlledAutocomplete from 'components/molecules/ControlledAutocomplete'
import ControlledSwitch from 'components/molecules/ControlledSwitch'
import ControlledTextField from 'components/molecules/ControlledTextField'
import ControlledUploadFile from 'components/molecules/ControlledUploadFile'
import { useGetAvailabilitiesQuery } from 'domains/availabilities/queries'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { formatAvailabilities } from '../../formatters'
import { Props } from './types'

function Form({ onSubmit, variant }: Props) {
  const { t } = useTranslation()

  const { data: availabilities, isLoading: isLoadingAvailabilities } =
    useGetAvailabilitiesQuery()

  return (
    <Box
      component="form"
      onSubmit={onSubmit}
      sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
    >
      <ControlledTextField name="name" label={t('name')} />
      <ControlledTextField
        name="description"
        label={t('description')}
        multiline
        minRows={4}
      />
      <ControlledTextField name="author" label={t('author')} />
      <ControlledUploadFile name="picture" label={t('picture')} />
      <ControlledSwitch name="isPrincipal" label={t('is-principal')} />
      <ControlledTextField
        type="number"
        name="distance"
        label={t('distance')}
      />

      <ControlledAutocomplete
        multiple
        name="availabilities"
        TextFieldProps={{
          size: 'small',
          label: t('availabilities'),
        }}
        loading={isLoadingAvailabilities}
        options={formatAvailabilities(availabilities?.data)}
        isOptionEqualToValue={(option, _value) => option.id === _value.id}
      />
      <Button variant="contained" color="secondary" type="submit">
        {variant === 'create' ? t('common-save') : t('common-update')}
      </Button>
    </Box>
  )
}

export default Form
