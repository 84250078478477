import { Box } from '@mui/material'
import { DataGrid as MUIDataGrid } from '@mui/x-data-grid'
import { formatFiltersToQueryString } from 'formatters/filters'
import React from 'react'
import { FormProvider } from 'react-hook-form'
import { useLocalStorage } from 'usehooks-ts'
import Toolbar from './components/Toolbar'
import useFiltersForm from './form'
import useGrid from './grid'
import { Props } from './types'

/**
 * [FTEL] Ce composant est une augmentation du composant DataGrid de MUIv5
 * https://mui.com/x/react-data-grid/
 */
function DataGrid(props: Props) {
  const {
    id,
    getEntities = () => {},
    components = {},
    loading: propsLoading,
    componentsProps,
    searchParams = {},
    defaultSort = [],
    additionalActions = [],
    baseFilters = '',
    options,
    ...rest
  } = props
  const [filtersFromLS, setFilterFromLS] = useLocalStorage(`${id}-filters`, [])
  const filterForm = useFiltersForm(filtersFromLS)
  const {
    rows,
    loading,
    filters,
    setFilters,
    setSearch,
    setPageSize,
    setCurrentPage,
    currentPage,
    pageSize,
    search,
    refetch,
    total = 0,
    sortModel,
    setSortModel,
  } = useGrid(
    getEntities,
    searchParams,
    filtersFromLS,
    defaultSort,
    baseFilters,
    options,
  )

  const filterUpdate = (_filters) => {
    setFilters(formatFiltersToQueryString(_filters))
    setFilterFromLS(_filters)
  }
  return (
    <Box
      sx={{
        flex: 1,
        '& .MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold' },
        bgcolor: 'white',
      }}
    >
      <FormProvider {...filterForm}>
        <MUIDataGrid
          experimentalFeatures={{ newEditingApi: true }}
          disableColumnFilter
          loading={loading || propsLoading}
          rows={rows || []}
          disableColumnMenu
          getRowHeight={() => 'auto'}
          rowCount={total}
          page={currentPage - 1}
          pageSize={pageSize}
          pagination
          onPageChange={(newPage) => setCurrentPage(newPage + 1)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          paginationMode="server"
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
          components={{
            Toolbar,
            ...components,
          }}
          componentsProps={{
            ...componentsProps,
            toolbar: {
              onRefresh: refetch,
              ...componentsProps?.toolbar,
              search,
              setSearch,
              filterUpdate,
              filtersString: filters,
              additionalActions,
            },
          }}
          {...rest}
        />
      </FormProvider>
    </Box>
  )
}

export default DataGrid
