import { Box, Typography, Avatar, Paper } from '@mui/material'
import { isEmpty } from 'lodash'
import { DateTime } from 'luxon'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { STEP_TIME_FORMAT } from './constants'
import { LOCALE_ZONE } from '../UseRunInformations/constants'

function StepsDashboard({ userRun }) {
  const { t } = useTranslation()
  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h2" sx={{ mb: 2 }} fontWeight={700}>
        {t('steps-details')}
      </Typography>
      <Paper
        sx={{
          mt: 2,
          display: 'flex',
          justifyContent: 'space-evenly',
          bgcolor: 'primary.main',
        }}
      >
        {userRun.userRunSteps
          .sort((a, b) => a.index - b.index)
          .map((s) => (
            <Box
              key={s.stepId}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                my: 1,
              }}
            >
              <Avatar
                key={s.stepId}
                sx={{
                  width: (theme) => theme.spacing(8),
                  height: (theme) => theme.spacing(8),
                  bgcolor: s.dateMarked ? 'secondary.main' : '',
                }}
              >
                <Typography variant="h2" color="dark">
                  {s.index}
                </Typography>
              </Avatar>
              {!isEmpty(s.dateMarked) && (
                <Typography sx={{ mt: 1 }}>
                  {DateTime.fromISO(s.dateMarked)
                    .setLocale(LOCALE_ZONE)
                    .toFormat(STEP_TIME_FORMAT)}
                </Typography>
              )}
            </Box>
          ))}
      </Paper>
    </Box>
  )
}

export default StepsDashboard
