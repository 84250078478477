import { isNil } from 'lodash'
import { DateTime } from 'luxon'
import {
  HOUR_MINUTE_FORMAT,
  HOUR_MINUTE_NULL_RESULT,
  LOCALE_ZONE,
  TIME_FORMAT,
} from './constants'

export function DateToLocalDateString(date: Date) {
  return DateTime.fromISO(date).toLocaleString()
}
export function DateToLocalTimeString(date: Date) {
  return DateTime.fromISO(date).setLocale(LOCALE_ZONE).toFormat(TIME_FORMAT)
}

/** Renvoi true si la date est valide
 */
export function checkNullableDate(date) {
  if (isNil(date)) return false
  return (
    DateTime.fromISO(date).toFormat(HOUR_MINUTE_FORMAT) !==
    HOUR_MINUTE_NULL_RESULT
  )
}
export function formatStartEndDateForDisplay(t, startDate, endDate) {
  const hasEndTime = checkNullableDate(startDate)
  return `${t('on-date')} ${DateToLocalDateString(startDate)} ${
    hasEndTime ? t('from-date') : t('at')
  } ${DateToLocalTimeString(startDate)} ${
    hasEndTime ? `${t('at')} ${DateToLocalTimeString(endDate)}` : ''
  }`
}
