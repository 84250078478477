import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

function useCreateThematicForm() {
  const { t } = useTranslation()
  const defaultValues = {
    name: '',
    description: '',
    distance: 0,
    isPrincipal: false,
    duration: 0,
    picture: null,
    levels: [],
    customers: [],
    availabilities: [],
    author: '',
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('required-field')),
    description: Yup.string().required(t('required-field')),
    distance: Yup.number().required(t('required-field')),
    duration: Yup.number().required(t('required-field')),
    isPrincipal: Yup.bool().required(t('required-field')),
    picture: Yup.object().required(t('required-field')).nullable(),
    author: Yup.string().nullable(),
  })

  return useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })
}

export default useCreateThematicForm
