import { Box, Button, CircularProgress, Icon, darken } from '@mui/material'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { downloadFile } from 'components/organisms/UserRunsList/helpers'
import { DIVIDER, EXPORT, LINK, getMenuItems } from 'config/drawer'
import { useAuth } from 'contexts/AuthContext'
import { ERROR, useSnackbar } from 'contexts/SnackbarContext'
import { useExportExcel } from 'domains/exports/mutations'
import { LOADER_CIRCULAR_PROGRESS_SIZE } from 'enums/loader'
import { isEmpty } from 'lodash'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { SelectedPath } from './helper'

function MenuItems() {
  const { pathname } = useLocation()
  const exportExcel = useExportExcel()
  const { user } = useAuth()
  const { t } = useTranslation()
  const [loader, setLoader] = React.useState(false)
  const { popSnackbar } = useSnackbar()

  const dlFile = async () => {
    setLoader(true)
    try {
      const response = await exportExcel.mutateAsync({})
      if (!isEmpty(response)) {
        downloadFile(response)
      }
    } catch {
      popSnackbar(t('export-error'), ERROR)
    }
    setLoader(false)
  }

  return (
    <Box
      sx={{
        maxHeight: {
          xs: (theme) => theme.spacing(75),
        },
        overflowY: {
          xs: 'auto',
        },
        mb: {
          xs: 1,
        },
      }}
    >
      <List sx={{ display: 'flex', flexDirection: 'column', gap: 2, px: 0.75 }}>
        {getMenuItems(t, user).map(({ key, type, title, icon, to = '#' }) => {
          const isSelected = SelectedPath(to, pathname)
          switch (type) {
            case LINK:
              return (
                <ListItem
                  key={key}
                  disablePadding
                  sx={{
                    height: (theme) => theme.spacing(4),
                    overflow: 'hidden',
                    borderRadius: 1,
                  }}
                >
                  <ListItemButton
                    component={Link}
                    to={to}
                    selected={isSelected}
                    sx={{
                      bgcolor: 'text.secondary',
                      '&:hover': {
                        bgcolor: (theme) =>
                          darken(theme.palette.text.secondary, 0.1),
                      },
                      '&.Mui-selected, &.Mui-selected:hover': {
                        bgcolor: 'text.primary',
                      },

                      borderRadius: 1,
                    }}
                  >
                    <ListItemIcon
                      sx={{ minWidth: (theme) => theme.spacing(4) }}
                    >
                      {icon && (
                        <Icon
                          component={icon}
                          sx={{
                            color: isSelected
                              ? 'text.secondary'
                              : 'text.primary',
                            fill: isSelected
                              ? 'text.secondary'
                              : 'text.primary',
                            '& path': {
                              fill: (theme) =>
                                isSelected
                                  ? theme.palette.text.secondary
                                  : theme.palette.text.primary,
                              color: isSelected
                                ? 'text.secondary'
                                : 'text.primary',
                            },
                          }}
                        />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        color: isSelected ? 'text.secondary' : 'text.primary',
                        borderRadius: 1,
                      }}
                      primary={title}
                      primaryTypographyProps={{
                        noWrap: true,
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              )

            case DIVIDER:
              return <Divider key={key} />

            case EXPORT:
              return (
                <ListItem
                  key={key}
                  disablePadding
                  sx={{
                    height: (theme) => theme.spacing(4),
                    overflow: 'hidden',
                    borderRadius: 1,
                  }}
                >
                  <ListItemButton
                    component={Button}
                    onClick={dlFile}
                    disabled={loader}
                    sx={{
                      bgcolor: 'text.secondary',
                      '&:hover': {
                        bgcolor: (theme) =>
                          darken(theme.palette.text.secondary, 0.1),
                      },
                      '&.Mui-selected': {
                        bgcolor: 'text.primary',
                      },
                      '&.Mui-selected:hover': {
                        bgcolor: 'text.primary',
                      },
                    }}
                  >
                    <ListItemIcon
                      sx={{ minWidth: (theme) => theme.spacing(4) }}
                    >
                      {loader ? (
                        <CircularProgress
                          size={LOADER_CIRCULAR_PROGRESS_SIZE}
                          color="primary"
                        />
                      ) : (
                        <Icon
                          component={icon}
                          sx={{
                            color: isSelected
                              ? 'text.secondary'
                              : 'text.primary',
                            fill: isSelected
                              ? 'text.secondary'
                              : 'text.primary',
                          }}
                        />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        color: isSelected ? 'text.secondary' : 'text.primary',
                      }}
                      primaryTypographyProps={{
                        noWrap: true,
                      }}
                      primary={title}
                    />
                  </ListItemButton>
                </ListItem>
              )

            default:
              return null
          }
        })}
      </List>
    </Box>
  )
}

export default MenuItems
