import { useQueryWrapper } from 'domains/helpers'
import { PaginatedType } from 'domains/types'
import { AVAILABILITIES, AVAILABILITY } from './templates'
import { Availability } from './types'

export function useGetAvailabilitiesQuery(
  params?,
  options?,
  filtersPaginationAndSort?,
) {
  return useQueryWrapper<PaginatedType<Availability>>(
    'GET',
    AVAILABILITIES,
    params,
    options,
    filtersPaginationAndSort,
  )
}

export function useGetAvailabilityQuery(params?, options?) {
  return useQueryWrapper<Availability>('GET', AVAILABILITY, params, options)
}
